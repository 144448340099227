import {
  Drawer,
  FormControlLabel as MFormControlLabel,
  Grid,
  styled,
} from "@mui/material";
import {
  ControlledAutoComplete,
  ControlledSelect,
  ControlledSwitch,
  ControlledTextField,
  CurrencyTextField,
} from "../../components/inputs";
import { Clear, Person, Search } from "@mui/icons-material";
import { Button, MenuItem, Form } from "../../components";
import React, { useCallback, useEffect, useState } from "react";
import { defaultOptions } from "../../styles/theme";

const ActionButton = styled(Button)`
  margin-top: 15px;
`;

const Text = styled("p")`
  margin: 2px;
`;

const FormControlLabel = styled(MFormControlLabel)`
  & .MuiTypography-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
    theme.fonts.size.inputs[size].label};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey._300};
  }
`;

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    width: 350px;
    height: calc(100% - 100px);
    top: 64px;
    padding: 20px;
  }

  ${({ theme }) => `${theme.breakpoints.up("sm")} {
  	& .MuiDrawer-paper {
		}
	`}
`;

const Label = styled("label")`
  margin: 0 0 0 4px;
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme, size = "normal" }) =>
    theme.fonts.size.inputs[size].label};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey._300};

  &:focus {
    color: ${({ theme }) => theme.colors.grey._800};
  }
`;

const getFilterLabel = (option, domain, property = "nome") => {
  if (!!option) {
    const domainOption = domain?.find(
      (d) => d.id === option.id || d.id === option
    );
    if (!!domainOption) {
      return domainOption[property];
    }
  }
  return "";
};

const FiltrosOperacoes = ({
  open,
  formProps,
  onClose,
  filtros,
  exibeResponsavel,
  handleSubmit,
}) => {
  const [situacoes, setSituacoes] = useState(filtros?.situacoes || []);
  const [responsaveis, setResponsaveis] = useState(filtros?.responsavel || []);
  const [etapas, setEtapas] = useState(filtros?.etapas || []);

  useEffect(() => {
    setSituacoes(filtros?.situacoes || []);
    setResponsaveis(filtros?.responsavel || []);
    setEtapas(filtros?.etapas || []);
  }, [filtros]);

  return (
    <StyledDrawer
      anchor={"right"}
      variant="temporary"
      open={open}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Grid container paddingBottom={1}>
          <Grid item xs={12}>
            <ControlledSelect
              name={"banco"}
              label={"Banco"}
              multiple
              formProps={formProps}
            >
              {filtros?.instituicoesFinanceiras
                ?.filter((instituicao) => instituicao.habilitado !== false)
                ?.sort((a, b) => (a.nomeFantasia < b.nomeFantasia ? -1 : 1))
                .map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.nomeFantasia}
                  </MenuItem>
                ))}
            </ControlledSelect>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*    <ControlledTextField*/}
          {/*        name={"nome"}*/}
          {/*        label={"Nome"}*/}
          {/*        formProps={formProps}*/}
          {/*    />*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <ControlledTextField
              name={"nomeOuCpfCnpj"}
              label={"Nome ou CPF / CNPJ"}
              type="both"
              // InputProps={{
              //     inputComponent: CpfCnpjTextField,
              // }}
              formProps={formProps}
            />
          </Grid>
          <Grid spacing={1} container justifyContent={"space-between"}>
            <Grid item xs={12} marginTop={2}>
              <Label htmlFor="valorFinanciamento">Valor do Imóvel:</Label>
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name={"valorImovelMin"}
                label={"Min."}
                placeholder={"R$"}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  allowNegative: false,
                }}
                formProps={formProps}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name={"valorImovelMax"}
                label={"Máx."}
                placeholder={"R$"}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  allowNegative: false,
                }}
                formProps={formProps}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent={"space-between"}
            marginBottom={2}
          >
            <Grid item xs={12} marginTop={1}>
              <Label>Valor do Financiamento:</Label>
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name={"valorFinanciamentoMin"}
                label={"Min."}
                placeholder={"R$"}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  allowNegative: false,
                }}
                formProps={formProps}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name={"valorFinanciamentoMax"}
                label={"Máx."}
                placeholder={"R$"}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  allowNegative: false,
                }}
                formProps={formProps}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ControlledSelect
              name={"produto"}
              label={"Produto"}
              multiple
              formProps={formProps}
              onBlur={() => {
                setSituacoes(
                  filtros?.situacoes.filter((s) => {
                    const produto = formProps.getValues("produto");
                    if (produto.length === 0) {
                      return true;
                    } else {
                      return !!produto.find((p) => s.idProduto === p);
                    }
                  })
                );

                setEtapas(
                  filtros?.etapas.filter((s) => {
                    const produto = formProps.getValues("produto");
                    if (produto.length === 0) {
                      return true;
                    } else {
                      const produtoWorkflow = filtros?.produtosWorkflow.find(
                        (pw) => pw.idWorkflow === s.idWorkflow
                      );
                      return !!produto.find(
                        (p) => produtoWorkflow?.idProduto === p
                      );
                    }
                  })
                );
              }}
            >
              {filtros?.produtos
                ?.sort((a, b) => (a.nome < b.nome ? -1 : 1))
                .map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.nome}
                  </MenuItem>
                ))}
            </ControlledSelect>
          </Grid>
          <Grid item xs={12}>
            <ControlledAutoComplete
              domain={situacoes
                .filter((s) => {
                  const hasGroup = filtros?.produtos.some(
                    (p) => p.id === s.idProduto
                  );
                  return hasGroup;
                })
                .sort((a, b) => {
                  const groupA =
                    filtros?.produtos.find((p) => p.id === a.idProduto)?.nome ||
                    "";
                  const groupB =
                    filtros?.produtos.find((p) => p.id === b.idProduto)?.nome ||
                    "";

                  if (groupA !== groupB) {
                    return groupA.localeCompare(groupB);
                  }

                  return a.nome.localeCompare(b.nome);
                })
                .map((s) => ({
                  ...s,
                  groupLabel: filtros?.produtos.find(
                    (p) => p.id === s.idProduto
                  )?.nome,
                }))}
              label={"Situação"}
              idField={"id"}
              groupBy={(option) => option?.groupLabel}
              getOptionLabel={(option) =>
                getFilterLabel(option, filtros?.situacoes)
              }
              name={`situacao`}
              formProps={formProps}
              multiple={true}
              autocompleteProps={{
                disabled: false,
                clearText: "Limpar",
                loadingText: "Carregando opções",
                noOptionsText: "Nenhuma opção disponível",
                disableCloseOnSelect: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutoComplete
              domain={etapas
                .map((s) => {
                  const produtoWorkflow = filtros?.produtosWorkflow.find(
                    (pw) => pw.idWorkflow === s.idWorkflow
                  );
                  return {
                    ...s,
                    groupLabel: filtros?.produtos.find(
                      (p) => p.id === produtoWorkflow?.idProduto
                    )?.nome,
                    nome: getFilterLabel(s, filtros?.etapas),
                  };
                })
                .filter((s) => s.groupLabel)
                .sort((a, b) => {
                  const groupCompare = (a.groupLabel || "").localeCompare(
                    b.groupLabel || ""
                  );

                  if (groupCompare !== 0) {
                    return groupCompare;
                  }

                  return (a.nome || "").localeCompare(b.nome || "");
                })}
              label={"Nome da Fase"}
              idField={"id"}
              groupBy={(option) => option?.groupLabel}
              getOptionLabel={(option) =>
                getFilterLabel(option, filtros?.etapas)
              }
              name={`fase`}
              formProps={formProps}
              multiple={true}
              autocompleteProps={{
                disabled: false,
                clearText: "Limpar",
                loadingText: "Carregando opções",
                noOptionsText: "Nenhuma opção disponível",
                disableCloseOnSelect: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledSelect
              name={"responsavelFase"}
              label={"Responsável da Fase"}
              multiple
              formProps={formProps}
            >
              {filtros?.responsaveis?.map((state) => (
                <MenuItem key={state} value={state.split("")[0]}>
                  {state}
                </MenuItem>
              ))}
            </ControlledSelect>
          </Grid>
          {filtros?.parceiros.length > 1 ? (
            <Grid item xs={12}>
              <ControlledAutoComplete
                onBlur={() => {
                  setResponsaveis(
                    filtros?.usuariosResponsaveis.filter((r) => {
                      const parceiro = formProps.getValues("parceiro");
                      if (!parceiro) {
                        return true;
                      } else {
                        return parceiro.id === r.idParceiro;
                      }
                    })
                  );
                }}
                domain={filtros?.parceiros?.sort((a, b) =>
                  a.nomeFantasia < b.nomeFantasia ? -1 : 1
                )}
                label={"Parceiro"}
                idField={"id"}
                // groupBy={(option) => option?.groupLabel}
                getOptionLabel={(option) =>
                  getFilterLabel(option, filtros?.parceiros, "nomeFantasia")
                }
                name={`parceiro`}
                formProps={formProps}
                autocompleteProps={{
                  disabled: false,
                  clearText: "Limpar",
                  loadingText: "Carregando opções",
                  noOptionsText: "Nenhuma opção disponível",
                }}
              />
            </Grid>
          ) : null}
          {exibeResponsavel ? (
            <Grid item xs={12}>
              <ControlledAutoComplete
                domain={responsaveis.sort((a, b) => (a.nome < b.nome ? -1 : 1))}
                label={"Responsável"}
                idField={"id"}
                // groupBy={(option) => option?.groupLabel}
                getOptionLabel={(option) =>
                  getFilterLabel(option, responsaveis)
                }
                name={`responsavel`}
                formProps={formProps}
                autocompleteProps={{
                  disabled: false,
                  clearText: "Limpar",
                  loadingText: "Carregando opções",
                  noOptionsText: "Nenhuma opção disponível",
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} marginTop={2}>
            <ControlledSwitch
              name={"somentePendentesMinhaAtuacao"}
              formProps={formProps}
              label="Exibir somente operações pendentes da minha atuação"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12} marginTop={2}>
            <ControlledSwitch
              name={"somenteOperacoesComPendencias"}
              formProps={formProps}
              label="Exibir somente operações com pendência(s) encontrada(s)"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={6} padding={1}>
            <ActionButton
              _color_={"primary"}
              size={"small"}
              onClick={() => formProps.reset()}
              fullWidth
              variant={"outlined"}
            >
              <Clear />
              <Text>Limpar</Text>
            </ActionButton>
          </Grid>
          <Grid item xs={6} padding={1}>
            <ActionButton
              size={"small"}
              fullWidth
              type="submit"
              variant={"contained"}
            >
              <Search />
              <Text>Buscar</Text>
            </ActionButton>
          </Grid>
        </Grid>
      </Form>
    </StyledDrawer>
  );
};

export default FiltrosOperacoes;
